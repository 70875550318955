<template>
    <v-container>
        <div class="mt-12">
            <h3 class="text-center">{{ $t("message.label-register-has-been-sent") }}</h3>
            <v-divider style="background-color: black"></v-divider>
            <p class="text-center pt-2">
                {{ $t("message.label-email-register-sent") }}
            </p>
            <v-spacer class="mt-4"></v-spacer>
            <p class="text-center">
            {{ $t("message.label-about-cek-email") }}    
            </p>
        </div>
    </v-container>
</template>

<script>
export default {
    name: "VefifyEmailRegister"
}
</script>

<style>

</style>